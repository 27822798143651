import chevron from '@a/icons/chevron.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { PrimaryBtn } from './buttons';
import { smTxt } from './layout/styles/Typography';
import { flexStart } from './layout/styles/classes';

export const Form = styled.form<{ submit: boolean }>`
    position: relative;
    width: 100%;
    max-width: 528px;
    scroll-margin-top: 100px;

    > h2 {
        margin: 8px 0 32px;
    }

    > button:last-of-type {
        margin: 24px auto 0;
        display: flex;
        width: 100%;
    }

    ${({ submit }) =>
        submit &&
        css`
            * {
                pointer-events: none;
                visibility: hidden;
            }
        `};
`;

export const Input = styled.input<{ rows?: number; setColor?: boolean; select?: boolean }>`
    ${smTxt};
    color: ${({ theme, setColor }) => (setColor ? '#9EA5A8' : theme.colors.blue4)};
    border: 1px solid #9ea5a8;
    background-color: ${({ theme }) => theme.colors.gray1};
    border-radius: 5px;
    width: 100%;
    padding: 15px 24px;
    display: block;
    outline: none;
    transition:
        border-color 0.3s ease-in,
        opacity 0.3s ease-in-out;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 160%;
    resize: none;

    :hover {
        border-color: ${({ theme }) => theme.colors.blue4};
    }

    :focus-visible {
        border-color: ${({ theme }) => theme.colors.blue4};
    }

    :focus-within {
        color: ${({ theme }) => theme.colors.blue4};
    }

    ::placeholder {
        color: #9ea5a8;
    }

    :disabled {
        pointer-events: none;
        opacity: 0.7;
    }

    option {
        text-transform: capitalize;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        text-fill-color: ${({ theme }) => theme.colors.blue4};
        color: ${({ theme }) => theme.colors.blue4};
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        transition: background-color 50000s ease-in-out 0s;
    }

    :not(:placeholder-shown):invalid {
        border-color: #d21717;
    }

    ${({ setColor }) =>
        setColor &&
        css`
            :not(:placeholder-shown):invalid {
                border-color: #9ea5a8;
            }

            :focus-within:invalid {
                border-color: #d21717;
            }

            text-transform: uppercase;
            padding: 14px 24px;
        `};

    ${({ select }) =>
        select &&
        css`
            background-image: url(${chevron});
            background-position: center right 20px;
            background-repeat: no-repeat;
            appearance: none;

            &::-ms-expand {
                display: none;
            }
        `};
`;

export const Label = styled.label`
    display: block;
    margin-bottom: 16px;

    > span {
        color: ${({ theme }) => theme.colors.gray5};
        font-weight: 400;
        margin-bottom: 10px;
        transition: color 0.3s ease-in-out;
        display: block;

        > strong {
            color: #d21717;
        }
    }

    :hover,
    :focus-within {
        > span {
            color: ${({ theme }) => theme.colors.blue4};
        }
    }
`;

export const FormFlex = styled.div<{ columns?: string }>`
    display: grid;
    column-gap: 24px;
    grid-template-columns: repeat(auto-fit, min(100%, 330px));

    @media (min-width: 600px) {
        grid-template-columns: ${({ columns }) => columns || 'repeat(auto-fit, min(100%, 330px))'};

        > label {
            min-width: 220px;
        }
    }
`;

export const CheckBoxes = styled.div<{ big?: boolean }>`
    > p {
        margin: 0 0 12px;
    }

    label {
        margin-left: 8px;
        transition: color 0.3s ease-in-out;

        :hover {
            color: ${({ theme }) => theme.colors.blue3};
        }
    }

    input[type='checkbox'] {
        width: 22px;
        height: 22px;
        accent-color: ${({ theme }) => theme.colors.blue4};
        transition:
            accent-color 0.3s ease-in-out,
            box-shadow 0.3s ease-in-out;
        position: relative;
        appearance: none;
        outline: none;

        ::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 7px;
            box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.gray3};
            transition: box-shadow 0.3s ease-in-out;
        }

        ::after {
            content: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="16" height="16" viewBox="0 0 512 512"%3E%3Cpath fill="white" d="M437.3 30L202.7 339.3L64 200.7l-64 64L213.3 478L512 94z"%2F%3E%3C%2Fsvg%3E');
            position: absolute;
            top: 3px;
            left: 3px;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
        }

        :hover {
            ::before {
                box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.blue3};
            }
        }

        :focus-visible {
            ::before {
                box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.blue3};
            }
        }

        :checked {
            ::before {
                box-shadow: inset 0 0 0 22px ${({ theme }) => theme.colors.blue4};
            }

            ::after {
                opacity: 1;
            }

            :hover {
                ::before {
                    box-shadow: inset 0 0 0 22px ${({ theme }) => theme.colors.blue5};
                }
            }
            :focus-visible {
                ::before {
                    box-shadow: inset 0 0 0 22px ${({ theme }) => theme.colors.blue5};
                }
            }
        }
    }

    > div:first-of-type {
        > div {
            ${flexStart};
            align-items: center;
            margin-bottom: 16px;
        }

        @media (min-width: 600px) {
            display: grid;
            grid-auto-flow: column;
            justify-content: start;
            gap: 40px;
        }
    }

    ${({ big, theme }) =>
        big &&
        css`
            border-top: 1px solid ${theme.colors.gray3};
            padding-top: 16px;

            > div:first-of-type {
                @media (min-width: 600px) {
                    grid-auto-flow: unset;
                    grid-template-columns: repeat(auto-fit, 230px);
                    gap: 8px 40px;
                }

                @media (min-width: 1280px) {
                    grid-template-columns: repeat(4, auto);
                    justify-content: space-between;
                }
            }
        `};

    .form-checkbox-other {
        ${flexStart};
        align-items: center;
        margin-top: 8px;
        margin-bottom: 16px;

        > label {
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            width: 1px;
            margin: -1px;
            padding: 0;
            overflow: hidden;
        }

        > div {
            ${flexStart};
            align-items: center;
            margin-right: 16px;
        }
    }
`;

const StyledThanks = styled.div<{ submit: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: ${({ submit }) => (submit ? 10 : -1)};
    opacity: ${({ submit }) => (submit ? 1 : 0)};
    transform: ${({ submit }) => (submit ? 'scale(1)' : 'scale(0)')};
    transition: all 0.3s ease-in;
    transition-property: opacity, transform;
    background-color: ${({ theme }) => theme.colors.gray1};

    &,
    > h2,
    > p,
    > button {
        visibility: ${({ submit }) => (submit ? 'visible' : 'hidden')} !important;
        pointer-events: ${({ submit }) => (submit ? 'all' : 'none')} !important;
    }

    > h2 {
        margin: 0;
        color: ${({ theme }) => theme.colors.blue4};
    }

    > p {
        font-size: 1.25rem;
        color: ${({ theme }) => theme.colors.gray5};
        margin: 2rem 0;
    }

    @media (min-width: 1024px) {
        padding: 40px;
    }
`;

export const Thanks = ({
    submit,
    setSubmit,
}: {
    submit: boolean;
    setSubmit: React.Dispatch<React.SetStateAction<boolean>>;
}) => (
    <StyledThanks submit={submit}>
        <h2>Thank you</h2>
        <p>Someone will be right with you shortly!</p>

        <PrimaryBtn onClick={() => setSubmit(false)} aria-hidden={!submit}>
            Resubmit Form
        </PrimaryBtn>
    </StyledThanks>
);
